
import React from 'react'
import Banner from '../components/banner/Banner';
import Info from '../components/info/Info';
import Contact from '../components/contact/Contact';
import Cards from '../components/cards/Cards';



export const Home = () => {

  return (
<>
<Banner/>
<Info />
<Cards /> 
<Contact/>
</>
 
 
  )
}

export default Home;

