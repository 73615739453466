import React from 'react';
import './info.css';
import misionrm from '../../images/misionrm.jpeg';
import visionrm from '../../images/visionrm.jpeg';

const About = () => {
  return (
    <div  className="row bg-light">
    <div className="banner2">
    <h1 className="letraBanner2">"La justicia es la constante y perpetua voluntad de dar a cada uno su derecho" - Justiniano</h1>
    </div>
    <div  className="col-12  mb-5">
  <h1 className="titleA">Quiénes Somos</h1>
 
  <p className="textA">RM ABOGADOS es una firma que cuenta con profecionlaes de alta trayectoria litigiosa en diversas áreas del conocimiento jurídico, nos hemos caracterizado por nuestra honestidad y altos valores sociales, pues tenemos la firme convicción de que los derechos de las personas deben ser respetados y garantizados. 

  <br/><br/>Nuestra firma se enorgullece de ofrecer un servicio excepcional con los más altos estándares éticos y profesionales, nos esforzamos por mantener relaciones de confianza y lealtad con nuestros clientes, y creemos que la comunicación clara y frecuente es clave para lograr resultados exitosos. 
  
  <br/><br/>Creemos que el éxito se logra a través de la colaboración y el trabajo en equipo, nuestros profecionales aportan habilidades únicas, lo que nos permite abordar cada caso desde múltiples perspectivas y encontrar soluciones creativas y efectivas. 
  </p>
    </div>

    <div  className="col-12  mb-5">
    <h1 className="titleA2">Misión</h1>
    <div className="containerInfo">
    <p className="textA2" >Nuestra misión es proporcionar a nuestros clientes un asesoramiento legal personalizado, de alta calidad y rentable, que les permita alcanzar sus objetivos comerciales y personales. Nos esforzamos por construir relaciones de confianza y lealtad con nuestros clientes, basadas en la comunicación clara, la transparencia y el compromiso con los más altos estándares éticos y profesionales.</p>
    <img className="imgInfo2"  src={misionrm} alt="mision"/>
    </div>
    </div>

    <div  className="col-12  mb-5">
    <h1 className="titleA1">Visión</h1>
    <div className="containerInfo">
    <img className="imgInfo"  src={visionrm} alt="vision"/>
    <p className="textA2">Ser una firma de abogados líder en nuestro país, reconocida por nuestra excelencia en el servicio al cliente, la calidad de nuestro trabajo y nuestra capacidad para brindar soluciones jurídicas innovadoras y efectivas.</p>
    </div>
    </div>
   
    </div>
  )
}

export default About