import React from 'react';
import About from '../components/info/About';

export const Lafirma = () => {

  return (
    <>
  <About/>
    </>
  )
}

export default Lafirma