

import React, { useState, useEffect} from 'react';
import Service from '../components/service/Service';

export const Servicio = () => {



    return (
    <>
    <Service/>
    </>
    )
}

export default Servicio;